.faqQuestion h2:after{
border-bottom: 0px !important;
}
.faqAnswer{
    border: 2px black solid;
    padding: 10px;
    overflow: hidden;
}

.blackh3{
    color: black;
}