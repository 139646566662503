.triklFont{
    font-family: 'Palanquin Dark', sans-serif;
    color: black;
    overflow: hidden;
    font-size: 10rem;
    margin: 0;
}

.iconPic{
    height: 50%;
    width: 50%;
}

.bioPicClear{
    background-color: transparent;
    border: 0px;
}