.hobbiesTitle{
    font-family: 'Graduate', sans-serif;
    font-size: 5em;
}

.redFlame{
    background: url("../../assets/redFlame.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.greenFlame{
    background: url("../../assets/greenFlame.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.blueFlame{
    background: url("../../assets/blueFlame.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.purpleFlame{
    background: url("../../assets/purpleFlame.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.pinkFlame{
    background: url("../../assets/pinkFlame.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}




.bgFacts{
    background-color: #fff;
   color: black;
  padding: 20px;
  margin: 10px;
  /* border-radius: 1%; */
  box-shadow: 0 0 2px 2px grey;
}

.heroFont{
  font-family: 'Unica One', sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Unica+One&display=swap');


.greenBtn{
  background-color: rgb(30, 252, 130);
  color: white;
  cursor: pointer;
  padding: 15px;
  margin: 1rem;
  border-radius: 4px;
}
.greenBtn a{
  color: white;
}

.greenBtn :hover {
  background-color: #555;
  color: white;

  /* opacity: 50%; */
    transition: all 0.4s ease;
}

.flexCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.factHeader{
  font-size: 5rem;
  font-family: 'Faster One', cursive;
}
.factPic{
  border-radius: 5%;
  border: solid black 2px;
}

.factPicText{
  display: flex;
  justify-content: center;
  text-align: center
}

 .spotifyLink a{
  text-decoration: none;
  color: #000;
  padding: 10px;
  display: inline-block;
  font-size: 2rem;
}
 .spotifyLink a:hover {
  color: #1db954;
  /*  opacity: 50%;*/
  transition: all 0.5s ease;
 }


@import url('https://fonts.googleapis.com/css2?family=Faster+One&display=swap');