.whiteh3{
    color:white;
    font-size: 3rem;
  
  }  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .whiteh3 {font-size: 2rem;}
  }
  
  .whiteh4{
    color:white;
    font-size: 2rem;
  
  }  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .whiteh4 {font-size: 1rem;}
  }
  
  .power{
    color: white;
    font-family: 'Unica One', sans-serif;
 }

