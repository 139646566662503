
.header a,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-brand,
.navbar-light .navbar-nav .show>.nav-link {
    color: black;
    font-size: 1.3rem;
}
.header a:hover,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-brand:hover {
    text-decoration: underline;
     /* opacity: 50%; */
    transition: all 0.4s ease;}

.header{
    padding: 0rem;
    background-color: rgb(255, 255, 255);
    position:sticky;
     top:0;
     z-index: 99;
     /* box-shadow: 2px 2px 2px rgb(53, 53, 53); */
     box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px 0px;
    }    

.dropdown-item a {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529!important;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.linkBg{
    background-color: rgb(161, 32, 32);
    padding: 5px 10px;
    border-radius: 5%;
    color: white !important;
}

a .linkBg{
    color: white !important; 
}


