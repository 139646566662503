ul#contactInfo{
    list-style-type: none;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
    font-size: 1.5rem;
} 

.bigIcon{
    margin-top: .5em;
    font-size: 7em;
    /* color: rgb(161, 32, 32); */
}

ul.contactList{
    overflow: hidden;  
  
    margin-top: 0;
    margin-bottom: 1rem;
    list-style-type: none;
  }