/*BLACK BOX SECTION*/
.blackBox {
    color: white;
    background: #171718;
    padding: 0;
}

h1:after{
    content: ""; 
    display: block;
    width: 20%; 
    padding-top: 10px;
    border-bottom: 5px solid;
}
h2:after{
    content: ""; 
    display: block;
    width: 20%; 
    padding-top: 10px;
    /* border-bottom: 2px solid ; */
}

.blackBox h2{
    margin-left: 1rem;
}


.resumePics{
    height: auto;
    width: 70%;
}

.quoteIcon{
    font-size: 2rem;
    margin: 1rem 0;
}

ul.resumelinks{
    overflow: hidden;  
  
    margin-top: 0;
    font-size: x-large;
    margin-bottom: 1rem;
    list-style-type: none;
  }
  .resumelinks a{
  
    text-decoration: none;
    color: white;
  }
  .resumelinks a:hover{
    text-decoration: underline;
  }

  .skills{
      text-align: center;
      font-size: 2rem;
  }


  .cchs {
    color: white;
    background:  #06315c;
}

.csun{
    color: white;
    background:  #d01c2e;
}

.subway{
    color: white;
    background: #008938;
}

.jb{
    color: white;
    background: #000;
}
.curacao{
    color: white;
    background: #95c851;
}
.goldBox{
    color: black;
    background: rgb(255, 198, 39);
}

/* .bioPic{
    margin: 2rem 0;
    padding: 10px;
    background-color: #000;
    border-radius: 25rem;
    max-width: 100%;
    height: auto;

} */

.bioPic{
    background-color: #000;
    border-radius: 50%;
    border: none;
    max-width: 100%;

}


  blockquote{
    border-left: 4px solid #e8e8e8;
    padding-left: 15px;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;
  }

  .bg{
    background-color: #fff;
   color: black;
  padding: 20px;
  margin: 10px;
  /* border-radius: 1%; */
  box-shadow: 0 0 2px 2px grey;
}


.greyBox {
    background-color: rgb(245, 245, 245);
    color: black; 
  }

.textBox{
    border-left: 4px solid #e8e8e8;
    padding-left: 15px;
    font-size: 18px;
    letter-spacing: -1px;
}

.resHero{

    /* height: ; */
    width: 100%;
    padding: 0;
}

.redPattern{
    background: url("../../assets/redPattern.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.orangePattern{
    background: url("../../assets/orangePattern.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.greenPattern{
    background: url("../../assets/greenPattern.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.bluePattern{
    background: url("../../assets/bluePattern.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.purplePattern{
    background: url("../../assets/purplePattern.png");
    padding-top: 10em;
    padding-bottom: 10em;
    border-radius: 5px;
}

.resume-link a {
    text-decoration: underline;
      color: #000;
      display: inline-block;
  }
  
  .resume-link a:hover {
      color: #b31b1b;
      text-decoration: underline;
      /*  opacity: 50%;*/
      transition: all 0.4s ease;
  }