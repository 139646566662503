.redBox {
  padding-top: 5rem;
  color: white;
  background: #870000;
  /* fallback for old browsers */
  background: linear-gradient(to bottom, #870000, #190a05);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


.blueBox {
  padding-top: 5rem;
  color: white;
  background: #003f87;
  /* fallback for old browsers */
  background: linear-gradient(to bottom, #003f87, #050f19);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.greenBox {
  padding-top: 5rem;
  color: white;
  background: #0b8700;
  /* fallback for old browsers */
  background: linear-gradient(to bottom, #0b8700, #051905);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.purpleBox {
  padding-top: 5rem;
  color: white;
  background: #550087;
  /* fallback for old browsers */
  background: linear-gradient(to bottom, #550087, #090519);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

