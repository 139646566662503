.custom-shape-divider-top-1645407743 {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1645407743 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 89px;
}

.custom-shape-divider-top-1645407743 .shape-fill {
    fill: #F5F5F5;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
    .custom-shape-divider-top-1645407743 svg {
        width: calc(200% + 1.3px);
        height: 500px;
    }
}


