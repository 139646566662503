@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Julius+Sans+One&family=Raleway:wght@500&family=Spectral+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Graduate&display=swap');

.wrapper {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .wrapper {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .wrapper {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .wrapper {
    width: 1170px;
  }
}

.blog-wrapper {
  max-width: 950px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .blog-wrapper {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .blog-wrapper {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .blog-wrapper {
    width: 1170px;
  }
}

  .content-wrap {
    overflow: hidden;
    max-width: 950px;
    margin: 0 auto;
    padding: 50px;
}

html{
  scroll-behavior: smooth;
}

  body{
    font-family: "Poppins", sans-serif;
    background-color: rgb(245, 245, 245);
    margin: 0;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

 
 code,pre{
     color: lime;
     background-color: black;
     padding: 1rem;
     overflow: auto;
 }

 .center{
   text-align: center;
 }
 .spacer{
  padding: 3rem 0;
}

.goTop{
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 28px;
  border: none;
  outline: none;
  background-color: rgb(161, 32, 32);
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;

}

.goTop a{
  color: white;
}

.goTop:hover {
  background-color: #555;
  color: white;

  /* opacity: 50%; */
    transition: all 0.4s ease;
}

.bioPic{
  background-color: #000;
  border-radius: 50%;
  border: none;
  max-width: 100%;

}

.row{
  margin: 0 !important;
}

.heroFont {
  font-family: "Unica One",sans-serif;
}