
.spotify {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background: linear-gradient(120deg, #1db954, #191414);
    text-align: center;
    font-size: 7rem;
}

.cashapp {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background: linear-gradient(120deg, #28c101, #FFFFFF);
    text-align: center;
    font-size: 7rem;
}


.psn {

    border-radius: 5%;
    margin: 10px;
    color: white;
    background: linear-gradient(120deg, #FFFFFF, #000080);
    text-align: center;
    font-size: 7rem;
}

.snap {
  text-shadow:
    1px  1px 2px black,
    1px -1px 2px black,
   -1px  1px 2px black,
   -1px -1px 2px black;
    border-radius: 5%;
    margin: 10px;
/*     stroke: black;
    stroke-width: 10; */
    color: white;
    background-color: #fffc01;
    text-align: center;
    font-size: 100px;
}

.cord {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #7289d9;
    text-align: center;
    font-size: 100px;
}

.twitch {
    border-radius: 5%;
    margin: 10px;
    background-color: #9e31fe;
    color: white;
    text-align: center;
    font-size: 100px;
}

.yt {

    border-radius: 5%;
    margin: 10px;
    color: white;
    background: linear-gradient(120deg, #ff0000, #282828);
    text-align: center;
    font-size: 100px;
}

.github {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #000;
    text-align: center;
    font-size: 100px;
}

.twitter {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #1da1f2;
    text-align: center;
    font-size: 100px;

}

.facebook {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #4064ac;
    text-align: center;
    font-size: 100px;

}

.instagram {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    text-align: center;
    font-size: 100px;

}

.otherSocials a {
    text-decoration: none;
    color: inherit;
    padding: 10px;
    display: inline-block;
}

.otherSocials a:hover {
    text-decoration: underline;
    /* opacity: 50%; */
    transition: all 0.4s ease;
}

.border {
    border: solid white 5px;
}

.grow {
    transition: all 1s ease-in-out;
}

.grow:hover {
    transform: scale(1.3);
}

.ajs-message.ajs-custom {
    color: white;
    background-color: dodgerblue;
    border: solid 5px white;
}




.steam {

    border-radius: 5%;
    margin: 10px;
    color: white;
    background: linear-gradient(120deg, #00adee, #000000);
    text-align: center;
    font-size: 7rem;
}

.reddit {

    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #ff4500;
    text-align: center;
    font-size: 7rem;
}

.nintendo {

    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #d11d0e;
    text-align: center;
    font-size: 7rem;
}

.soundcloud {

    border-radius: 5%;
    margin: 10px;
    color: white;
    background: linear-gradient(120deg, #ff8800, #ff3300);
    text-align: center;
    font-size: 7rem;
}

.linkedin {

    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #2563ac;
    text-align: center;
    font-size: 7rem;
}
.tiktok {
    border-radius: 5%;
    margin: 10px;
    color: white;
    background-color: #000000;
    text-align: center;
    font-size: 7rem;
}

.tiktoklogo {
    color: white;
    filter: drop-shadow(5px 2px 1px #FD3E3E) drop-shadow(-3px -2px 0px #4DE8F4);
}

.socials-wrap {
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
}

.custom-shape-divider-bottom-1645408672 {
    /* position: absolute; */
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1645408672 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 89px;
}

.custom-shape-divider-bottom-1645408672 .shape-fill {
    fill: #F5F5F5;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
    .custom-shape-divider-bottom-1645408672 svg {
        width: calc(200% + 1.3px);
        height: 500px;
    }
}