
footer{
    padding: 10px;
    background-color: #161616;
    color: #b6b6b6;
    /* text-align: center; */
    flex: 0 0 50px;/*or just height:50px;*/
    margin-top: auto;
}

.contact-info a {
    text-decoration: none;
      color: #b6b6b6;
      padding: 10px;
      display: inline-block;
  }
  
  .contact-info a:hover {
      color: #b31b1b;
      text-decoration: underline;
      /*  opacity: 50%;*/
      transition: all 0.4s ease;
  }

  .unselectable{
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  }