.not-found {
    text-align: center;
}

.not-found h3 {
    font-size: 3rem;
    color: rgb(192, 0, 0);

}

.not-found h4 {
    font-size: 2rem;
}

.pic-404 {
    border-radius: 50%;
    height: 50vh;
    width: auto;
}

.btn404 {
    background-color: seagreen;
    color: seashell;
    padding: 10px;
}

.btn404:hover{
    color: seashell;
}