.blogPic{
    padding: .25rem;
    background-color: #fff;
    border-radius: .25rem;
    width: 50%;
    height: 50%;
    transition: all 0.5s ease-in-out;
    display:block
}

.bgBlogs{
    background-color: #fff;
   color: black;
  padding: 20px;
  margin: 10px;
  /* border-radius: 1%; */
  box-shadow: 0 0 2px 2px grey;
  transition: all 0.5s ease-in-out;
}

.bgBlogs:hover {
    transform: scale(0.95);
}

.bgBlogs a { 
    display: block;
    color: inherit;
 }

 .bgBlogs a:hover { 
    display: block;
    color: inherit;
    text-decoration: none;
 }

.goHome{
    position: fixed;
    bottom: 20px;
    left: 30px;
    z-index: 99;
    font-size: 28px;
    border: none;
    outline: none;
    background-color: rgb(30, 207, 252);
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;

}

.goHome a{
    color: white;
}

.goHome:hover {
    background-color: #555;
    color: white;

    /* opacity: 50%; */
      transition: all 0.4s ease;
  }

  /* BLOG 10 */
  @keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(1.2,1.2);
    }
    100% {
        transform: scale(1,1);
    }
}

.scare{
      animation: zoominoutsinglefeatured 1s infinite ;
}

   .spin{
    animation: rotation 10s  infinite linear;
}

  @keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  @keyframes slide-in {
   0% { -webkit-transform: translateX(-100%); }
   100% { -webkit-transform: translateX(0%); }
}
  .slidein {
   animation: slide-in 2s ;
    
}
  
  .bounce{

    animation: updown 2s ease infinite;
}
  
  @keyframes updown {
  0% {
    transform: translateY(-10%);
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(-10%);
  }
}

dt{
  font-size: 1.5rem;
}
dd{
  font-style: italic;
  font-size: 1.1rem;
  padding-left: 2rem;
}

.soon{
  color:red;
}

.articlePic{
  width: 80%;
  height: auto;
}